/**
 * 傳送資料至fb server
 * @param event_name
 * @param user_data.emails
 * @param user_data.phone
 * @param user_data.first_name
 * @param user_data.last_name
 * @param user_data.date_birth
 * @param user_data.gender
 * @param user_data.country
 * @param user_data.city
 * @param user_data.zip_code
 * @param custom_data.value
 * @param custom_data.currency
 * @param custom_data.content_type
 * @param custom_data.content_name
 * @param custom_data.content_ids
 * @param custom_data.contents
 * @param custom_data.order_id
 * @param test_event_code
 * @constructor
 */

import Vue from 'vue'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
export default ({ isDev }) => {
  const postFbServerEvent = async (payload) => {
    try {
      payload.event_id = uuidv4()
      payload.source_url = document.location.href
      await axios.post('/api/fb-conversion-api', payload)
    } catch (err) {
      console.log('err', err)
    }
  }
  Vue.prototype.$FBConversionAPI = process.env.NODE_ENV_NAME === 'ec' && !isDev ? postFbServerEvent : () => {}
}
