import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/remote-config'
import { isSupported } from 'firebase/remote-config'

const firebaseConfigVender = {
  apiKey: 'AIzaSyBerJCDA7wt15Cie046iir1-TJCp0ZZlvk',
  authDomain: 'venderadmin.firebaseapp.com',
  databaseURL: 'https://venderadmin.firebaseio.com',
  projectId: 'venderadmin',
  storageBucket: 'venderadmin.appspot.com',
  messagingSenderId: '963482932819',
  appId: '1:963482932819:web:d4f0d7e3b68f8e9ed818a6'
}

const firebaseConfigSmartlife = {
  apiKey: 'AIzaSyDnx0MCawc9_F_FdNnqq-DRexAkUrO2FL8',
  authDomain: 'smartlife-3d231.firebaseapp.com',
  databaseURL: 'https://smartlife-3d231.firebaseio.com',
  projectId: 'smartlife-3d231',
  storageBucket: 'smartlife-3d231.appspot.com',
  messagingSenderId: '985332900753',
  appId: '1:985332900753:web:f3f5ff2ae479e4ca78f030'
}

const remoteConfigDefaults = {
  smartdaily_appversion_android: '3.46.1',
  smartdaily_appversion_ios: '3.46.1'
}

let firebaseVender
let firebaseSmartlife
let remoteConfigVender
let remoteConfigIsSupported
async function initFirebase () {
  if (!firebase.apps.length) {
    firebaseSmartlife = firebase.initializeApp(firebaseConfigSmartlife, 'firebaseSmartlife')

    if (process.client) { // 只能於 client 端
      remoteConfigIsSupported = await isSupported()
      if (!remoteConfigIsSupported) return
      firebaseVender = firebase.initializeApp(firebaseConfigVender, 'firebaseVender')
      remoteConfigVender = firebaseVender.remoteConfig()
      if (process.env.NODE_ENV_NAME !== 'ec') {
        remoteConfigVender.settings.minimumFetchIntervalMillis = 100000
      }
      remoteConfigVender.defaultConfig = remoteConfigDefaults
    }
  }
}

initFirebase()

export { firebaseSmartlife, firebaseVender, remoteConfigVender, remoteConfigIsSupported, remoteConfigDefaults }
