export default async function ({ $auth, redirect, route, ssrContext }) {
  if (process.server) {
    const setRobots = (() => {
      const { set } = ssrContext.meta.addApp('robots')
      set({
        meta: [
          { hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
          { hid: 'googlebot', name: 'googlebot', content: 'noindex, nofollow' }
        ]
      })
    })()
    if (!$auth.user) redirect(`/login?redirect=${route.path}`)
  }

  if ($auth.user) {
    // let the user in
  } else {
    let toRoute = route
    if (route.name === 'member') {
      toRoute = { name: 'member-orderList' }
    }
    $auth.$storage.setState('toRoute', toRoute)
    redirect('/login')
  }
}
