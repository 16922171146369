// import shareMutations from 'vuex-shared-mutations'

export default ({ $auth, store, query }) => {
  let routerSave = {}
  if ($auth.$storage.getCookie('routerSave')) {
    routerSave = {
      ...routerSave,
      ...$auth.$storage.getCookie('routerSave')
    }
  }
  if (query && (query.referrer || query.channel || query.discount)) {
    if (query.referrer) {
      routerSave.referrer = query.referrer
    }
    if (query.channel) {
      routerSave.channel = query.channel
    }
    if (query.discount) {
      routerSave.discount = query.discount
    }
  } else if (query && (query.utm_source && query.utm_medium)) {
    const isFacebook = query.utm_source === 'Facebook'
    const isAds = query.utm_medium === 'AD'
    store.commit('setIsFromFacebookAds', isFacebook && isAds)
  }
  if (Object.entries(routerSave).length > 0) {
    store.commit('setRouteSave', routerSave)
    $auth.$storage.setCookie('routerSave', routerSave, { expires: 1 })
  }

  // window.onNuxtReady((nuxt) => {
  //   shareMutations({
  //     predicate: ['setRouteSave']
  //   })(store)
  // })
}
