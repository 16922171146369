/**
 * sentry自訂方法
 */

export default (context, inject) => {
  const sentrySetUser = () => {
    if (context.$auth.loggedIn) {
      const {
        AName,
        AEmail,
        AId
      } = context.$auth.user
      context.$sentry.setUser({
        id: AId,
        username: AName,
        email: AEmail
      })
    } else {
      context.$sentry.setUser(null)
    }
  }

  const sentrySetScopeError = (type, data) => {
    if (!context.$sentry) return

    const {
      tracingHeaderValue,
      tracingHeaderKey
    } = context.store.state.sentryConfig
    context.$sentry.configureScope(scope => {
      scope.setTag(tracingHeaderKey, tracingHeaderValue)
      if (type === 'message') {
        context.$sentry.captureMessage(data)
      } else if (type === 'error') {
        context.$sentry.captureException(new Error(data))
      }
    })
  }

  inject('sentrySetUser', sentrySetUser)
  inject('sentrySetScopeError', sentrySetScopeError)
}
