import Vue from 'vue'
import VueGtag from 'vue-gtag'
export default ({ isDev, app }) => {
  if (isDev) {
    console.log('Skipping GA in development')
  } else {
    Vue.use(VueGtag, {
      config: { id: 'G-KB6Z3K919E' }
    },
    app.router)
  }
  const gaLogEvent = (event, param) => {
    // console.log(event, param);
    Vue.$gtag.event(event, {
      value: param
    })
  }
  Vue.prototype.$gaLogEvent = isDev ? () => {} : gaLogEvent
}
