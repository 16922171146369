import Vue from 'vue'

/**
 * 使用範例
 * this.$tooltip({
 *   title: '系統訊息',
 *   message: '提醒您，目前有3筆訂單尚未完成交寄回報，請儘速將包裹放置管理室。',
 *   icon: '',
 *   btnText: '前往通知物流',
 *   color: 'info',
 *   donFun: () => {
 *   }
 * })
 */

export default function ({ store }) {
  Vue.prototype.$tooltip = async (tooltip) => {
    let donFun = () => {
      store.commit('tooltip/toggle', false)
    }
    if (tooltip.donFun) {
      donFun = tooltip.donFun
    }
    await store.commit('tooltip/setTooltip', {
      ...tooltip
    })
    store.commit('tooltip/setDonFun', donFun)
    store.commit('tooltip/toggle', true)
  }
  Vue.prototype.$tooltip.close = () => {
    store.commit('tooltip/toggle', false)
    store.commit('tooltip/clear')
  }
}
