const tappayMerchantId = 'app_znbP2NpMAPQ3FqW5W21sskwxddAZgoU07kxBhHypL8tprv1FF7W1wVr8fdQ0'
const isWebview = () => {
  const useragent = navigator.userAgent
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari\/)', 'Android.*(wv|\.0\.0\.0)']
  const regex = new RegExp(`(${rules.join('|')})`, 'ig')
  return Boolean(useragent.match(regex))
}
export const install = () => {
  console.log('export install')
  return new Promise((resolve) => {
    if (typeof TPDirect !== 'undefined') resolve()
    const script = document.createElement('script')
    script.src = 'https://js.tappaysdk.com/sdk/tpdirect/v5.17.0'
    script.onreadystatechange = script.onload = () => {
      if (!script.readyState || /loaded|complete/.test(script.readyState)) {
        setTimeout(function () {
          resolve()
        }, 500)
      }
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}
export const importGoogleSdk = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = 'https://pay.google.com/gp/p/js/pay.js'
    script.onreadystatechange = script.onload = () => {
      if (!script.readyState || /loaded|complete/.test(script.readyState)) {
        setTimeout(function () {
          resolve()
        }, 500)
      }
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}
export const setUp = () => {
  console.log('export setUp')
  TPDirect.setupSDK(
    12921,
    tappayMerchantId,
    process.env.TAPPAY_MODE
  )
}
export const setApplePay = () => {
  console.log('export setApplePay')
  TPDirect.paymentRequestApi.setupApplePay({
    merchantIdentifier: 'merchant.knst.service',
    countryCode: 'TW'
  })
}
export const setGooglePay = () => {
  TPDirect.googlePay.setupGooglePay({
    googleMerchantId: process.env.GOOGLEPAY_MERCHANTID,
    allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    merchantName: '智生活|智樂家',
    billingAddressFormat: 'MIN',
    allowPrepaidCards: true,
    allowedCountryCodes: ['TW']
  })
}

export const canUseTappay = () => {
  if (typeof TPDirect === undefined) return null
  return TPDirect.paymentRequestApi.checkAvailability() && !isWebview()
}
export const isAndroidDevice = () => {
  return navigator.userAgent.match(/android/i)
}
export const isIosDevice = () => {
  return navigator.userAgent.match(/(iphone|ipad|ipod);?/i)
}
export const checkCanUseApplePay = (amount) => {
  console.log('checkCanUseApplePay amount', amount)
  const paymentSetting = {
    supportedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
    supportedMethods: ['apple_pay'],
    total: {
      label: '智生活|智樂家',
      amount: {
        currency: 'TWD',
        value: `${amount}`
      }
    },
    options: {
      requestPayerEmail: false,
      requestPayerName: false,
      requestPayerPhone: false,
      requestShipping: false
    }
  }
  return new Promise((resolve) => {
    TPDirect.paymentRequestApi.setupPaymentRequest(paymentSetting, function (result) {
      // NOTE: apple pay 只會檢查使用者是否有在 apple pay 裡面綁卡片
      console.log('result', result)
      let canUse = null
      if (!result.browserSupportPaymentRequest) {
        console.log('瀏覽器不支援 PaymentRequest')
        canUse = false
      } else if (result.canMakePaymentWithActiveCard === true) {
        console.log('該裝置有支援的卡片可以付款')
        canUse = true
      } else {
        console.log('該裝置沒有支援的卡片可以付款')
        canUse = true
      }
      resolve(canUse)
    })
  })
}
export const checkCanUseGooglePay = (amount) => {
  console.log('checkCanUseGooglePay amount', amount)
  const paymentSetting = {
    allowedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
    price: `${amount}`,
    currency: 'TWD'
  }
  return new Promise((resolve) => {
    TPDirect.googlePay.setupPaymentRequest(paymentSetting, function (err, result) {
      console.log('TPDirect.googlePay.setupPaymentRequest', result)
      resolve(result.canUseGooglePay)
    })
  })
}

export const getPrimeGooglePay = () => {
  return new Promise((resolve, reject) => {
    TPDirect.googlePay.getPrime((err, prime, result) => {
      console.log('googlePay.getPrime result', result)
      if (prime) {
        resolve(prime)
      } else {
        reject(err)
      }
    })
  })
}
export const getPrimeApplePay = () => {
  return new Promise((resolve, reject) => {
    TPDirect.paymentRequestApi.getPrime((result) => {
      console.log('paymentRequestApi.getPrime result', result)
      if (result.status === 0) {
        resolve(result.prime)
      } else {
        console.error('getPrime failed: ' + result.msg)
        reject(new Error(result.msg))
      }
    })
  })
}
export const getPrime = (payment) => {
  if (payment === 'apple-pay') {
    return getPrimeApplePay()
  } else if (payment === 'google-pay') {
    return getPrimeGooglePay()
  } else {
    return Promise.reject(new Error('no payment'))
  }
}
