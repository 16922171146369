const getDefaultState = () => {
  return {
    id: null,
    open: false,
    title: '',
    message: '',
    icon: 'alert-circle-full',
    btnText: null,
    color: 'info',
    targets: '',
    hasArrow: true,
    top: false,
    bottom: false,
    left: false,
    right: false,
    sticky: false,
    canClose: false,
    offset: () => [],
    maxWidth: 'none', // calc(100vw - 10px)
    options: () => {},
    round: false,
    donFun: () => { },
    theme: ''
  }
}

export const state = getDefaultState()

export const getters = {
}

export const mutations = {
  toggle (state, status) {
    state.open = status
  },
  setTooltip (state, tooltip) {
    Object.entries(tooltip).map(([key, value]) => {
      state[key] = value
    })
  },
  clear (state) {
    const s = getDefaultState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  setDonFun (state, donFun) {
    state.donFun = donFun
  }
}

export const actions = {
  open ({ commit }) {
    commit('toggle', true)
  },
  close ({ commit }) {
    commit('toggle', false)
    commit('clear')
  }
}
