export default function ({ req, store }) {
  if (process.server && req && req.method === 'POST') {
    return new Promise((resolve, reject) => {
      let reqData = ''
      req.on('data', chunk => {
        reqData += chunk
      })
      req.on('end', () => {
        const toJSONString = JSON.parse('{"' + reqData.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === '' ? value : decodeURIComponent(value) })
        resolve(store.commit('postBody', toJSONString))
      })
    })
  }
}
