export default {
  serviceList: state => state.serviceList,
  bannerList: state => state.bannerList,
  personalInfoDefaultAddress: state => _.find(state.personalInfo.address, { IsDefault: 1 }),
  postBody: state => state.postBody,
  postError: state => state.postError,
  isMobile: state => state.windowWidth <= state.mobileWidth,
  appVersionIos: state => state.appVersion.ios,
  appVersionAndroid: state => state.appVersion.android,
  isAgentOrder: state => Boolean(state.agentOrder.token) && !state.agentOrder.source.includes('GuestAgent'), // 會員代訂
  isGuestAgent: state => Boolean(state.agentOrder.token) && state.agentOrder.source.includes('GuestAgent'), // 訪客代訂
  isGuestMember: state => state.isGuestMember && state.auth.loggedIn && state.auth.user.Type === 3,
  completedOrderList: state => state.order.completedOrderList,
  hasRedPoint: state => state.order.orderList.notice.status1 || state.order.orderList.notice.status2 || state.order.orderList.notice.status3 || state.order.orderList.notice.status4 || state.order.hasOrderIsQuotedAndUnread
}
