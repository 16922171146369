import shareMutations from 'vuex-shared-mutations'

export default function ({ $auth, store, route }) {
  const isTabIndependent = route.query.agentToken // 代訂功能不同步頁籤間的登入 & vuex狀態

  $auth.$storage.watchState('loggedIn', (loggedIn) => {
    if (isTabIndependent) return

    if (!$auth.strategy.options.tokenRequired) {
      return
    }
    if (loggedIn) {
      // token could be in localStorage from other window activity,
      // but not in this window's state
      const token = $auth.syncToken($auth.strategy.name)
      // fix for local auth scheme
      if (token && $auth.strategy._setToken) {
        $auth.strategy._setToken(token)
      }
      $auth.syncRefreshToken($auth.strategy.name)
    } else {
      // set to null instead of false ( Auth.reset() )
      // so that $auth.syncToken will not think this is set ( to false )
      $auth.setToken($auth.strategy.name, null)
      $auth.setRefreshToken($auth.strategy.name, null)
    }
  })

  window.onNuxtReady((nuxt) => {
    if (!$auth.loggedIn) {
      $auth.setToken('local', null)
    }
    if (!isTabIndependent) {
      shareMutations({
        predicate: [
          $auth.options.vuex.namespace + '/SET'
        ]
      })(store)
    }
  })
}
