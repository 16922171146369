import Vue from 'vue'

/**
 * 使用範例
 * this.$alert('測試alertalert')
 * this.$alert({
 *   title: '測試alert',
 *   message: '12345',
 *   btnText: '確認',
 *   donFun: () => {
 *     // 可自訂按鈕事件，自訂事件需手動關閉
 *     this.$store.commit('alert/toggle', false)
 *   }
 * })
 */

export default function ({ store }) {
  Vue.prototype.$alert = (alert) => {
    let title = alert
    let message = ''
    if (typeof alert === 'string') {
    } else {
      title = alert.title
      message = alert.message
    }
    let donFun = () => {
      store.commit('alert/toggle', false)
    }
    if (alert.donFun) {
      donFun = alert.donFun
    }
    store.commit('alert/setDonFun', donFun)
    const alertData = {
      ...alert,
      title,
      message
    }
    store.commit('alert/setAlert', alertData)
    store.commit('alert/toggle', true)
  }
  Vue.prototype.$alert.close = async () => {
    await store.dispatch('alert/close')
  }
}
