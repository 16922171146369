export const state = () => ({
  axiosPendingList: []
})

export const getters = {
}

export const mutations = {
  clearAxiosPendingList (state) {
    state.axiosPendingList.forEach(obj => {
      const token = JSON.parse(obj)
      token.cancel && token.cancel()
    })
  },
  setAxiosPendingList (state, Data) {
    state.axiosPendingList = _.cloneDeep(Data)
  }
}

export const actions = {
  pushAxiosPending ({ commit, state }, data) {
    commit('setAxiosPendingList', [...state.axiosPendingList, data])
  },
  removeAxiosPending ({ commit, state }, data) {
    const axiosPendingList = _.remove(state.axiosPendingList, axios => {
      return JSON.parse(axios).config === data
    })
    commit('setAxiosPendingList', axiosPendingList)
  },
  clearAxiosPending ({ commit }) {
    commit('clearAxiosPendingList')
    commit('setAxiosPendingList', [])
  }
}
