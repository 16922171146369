import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import zhHant from 'vuetify/es5/locale/zh-Hant'

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        normal: '#999999',
        primary: '#15BEAE',
        secondary: '#FFF1B5',
        success: '#15BEAE',
        // accent: '#8c9eff',
        error: '#F05151'
      }
    }
  },
  treeShake: true,
  font: false,
  lang: {
    locales: { zhHant },
    current: 'zhHant'
  }
})
