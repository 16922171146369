import goTo from 'vuetify/es5/services/goto'
export const state = () => ({
  nowStep: 1,
  selectedService: '',
  packageList: [],
  step3Data: {},
  KTJNoPickUpOrders: null,
  packageCountLimit: {
    max: 50,
    min: 2
  },
  communityList: []
})

export const getters = {
}

export const mutations = {
  changeStep (state, step) {
    state.nowStep = step
    const stepper = document.querySelector('.multiple-layout .stepper')
    const header = document.querySelector('#header')
    if (!stepper || !header ||
      (stepper && header &&
        stepper.getBoundingClientRect().top <= header.clientHeight)) {
      goTo(0, { duration: 300, offset: 0, easing: 'easeInOutQuad' })
    }
  },
  updatePackageList (state, packageList) {
    state.packageList = _.cloneDeep(packageList)
  },
  updateKTJNoPickUpOrders (state, orderList) {
    state.KTJNoPickUpOrders = _.cloneDeep(orderList)
  },
  updateStep3Data (state, data) {
    state.step3Data = _.cloneDeep(data)
  },
  updateSelectedService (state, data) {
    state.selectedService = _.cloneDeep(data)
  },
  setCommunityList (state, data) {
    state.communityList = _.cloneDeep(data)
  }
}

export const actions = {
  async getOrderListByPage ({ commit, state, dispatch }, page) {
    const prePageCount = 30
    const { Data } = await this.$axios.$get('/api/v2/Order/GetOrderInfo', { params: { page, rows: prePageCount } })
    commit('pushOrderList', { Data: Data.Orders })
    if (Data.Orders.length < prePageCount) {
      commit('toggleStatus', { key: 'allLoadDown', status: true })
      return // 如果撈完了，就停止撈取
    }
    if (state.status.stop) {
      commit('toggleStatus', { key: 'allLoadDown', status: false })
      return
    }
    if (!state.status.firstDataDown) {
      commit('toggleStatus', { key: 'firstDataDown', status: true })
    }
    await dispatch('getOrderListByPage', page + 1)
  },
  async getHasKTJNoPickUpOrders ({ commit }) {
    try {
      const { data } = await this.$axios.get('/api/KTJ/GetNotPickUpOrders')
      commit('updateKTJNoPickUpOrders', data.Data.Orders)
    } catch (error) {
      return Promise.resolve()
    }
  }
}
