<template>
  <default-layout>
    <div class="container">
      <div class="banner-list">
        <div class="banner-box">
          <div
            v-for="i in 3"
            :key="i"
            class="banner"
          />
        </div>
        <div class="controls">
          <i
            v-for="i in 5"
            :key="i"
            class="dot"
          />
        </div>
      </div>
      <v-tabs
        v-model="tabValue"
        class="tabs"
        active-class="tab-active"
        optional
        grow
        center-active
        @change="changeTab"
      >
        <v-tab
          v-for="(tab, index) in tabList"
          :key="index"
          class="tab"
        >
          {{ tab.label }}
        </v-tab>
        <v-tabs-items v-model="tabValue">
          <v-tab-item
            v-for="(tab, index) in tabList"
            :key="index"
          >
            {{ tab.label }}
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '~/layouts/default.vue'
export default {
  components: {
    'default-layout': DefaultLayout
  },
  data () {
    return {
      tabValue: 0,
      tabList: [
        {
          label: '熱門服務',
          routeId: 'star',
          routeName: 'index-id'
        }, {
          label: '水電維修',
          routeId: 1,
          routeName: 'index-id'
        }, {
          label: '專業諮詢',
          routeId: 2,
          routeName: 'index-id'
        }, {
          label: '家電清洗',
          routeId: 3,
          routeName: 'index-id'
        }, {
          label: '居家清潔',
          routeId: 4,
          routeName: 'index-id'
        }, {
          label: '光纖上網',
          routeId: 5,
          routeName: 'index-id'
        }, {
          label: '旅遊休閒',
          routeId: 6,
          routeName: 'index-id'
        }, {
          label: '專業諮詢',
          routeId: 7,
          routeName: 'index-id'
        }
      ]
    }
  },
  mounted () {
    this.tabValue = parseInt(this.$nuxt.$route.params.id) || 0
  },
  methods: {
    changeTab (e) {
      this.$nuxt.$router.replace({
        name: this.tabList[e].routeName,
        params: {
          id: this.tabList[e].routeId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  width: 100%;
  padding: 0 140px;
  ::v-deep {
    .v-tabs-bar {
      .v-tabs-bar__content {
        .v-tab:not(.v-tab--active) {
          color: #999999;
        }
      }
    }
    .v-tabs-bar {
      height: auto;
    }
    .v-tabs-slider-wrapper {
      height: 8px !important;
    }
    .v-tabs-slider {
      background-color: $mainColor;
    }
  }
  .v-tabs-bar {
    .tab {
      color: #999999;
      display: inline-block;
      font-size: 21px;
      line-height: 29px;
      padding-top: 20px;
      padding-bottom: 16px;
      font-weight: bold;
      &.tab-active {
        color: $mainColor;
      }
    }
  }
}
.banner-list {
  $bannerheight: 320px;
  padding-top: 33px;
  padding-bottom: 31px - 13px;
  .banner-box {
    position: relative;
    height: $bannerheight;
    .banner {
      $width: 60%;
      position: absolute;
      width: $width;
      top: 50%;
      height: $bannerheight;
      background-color: #ccc;
      border-radius: 30px;
      transform: translate(-50%, -50%);
      &:nth-child(1) {
        left: calc(50% - #{$width} - 20px);
      }
      &:nth-child(2) {
        left: 50%;
      }
      &:nth-child(3) {
        left: calc(50% + #{$width} + 20px);
      }
    }
  }
  .controls {
    padding: 13px 0;
    display: flex;
    justify-content: center;
    .dot {
      $size: 13px;
      height: $size;
      width: $size;
      background-color: #D2D2D2;
      border-radius: 50%;
      + .dot {
        margin-left: 10px;
      }
      &:nth-child(4) {
        background-color: $mainColor;
      }
    }
  }
}
</style>
