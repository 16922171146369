export const state = () => ({
  open: false,
  type: '', // 1: normal 2: dialog
  title: '',
  message: '',
  messageHTML: '',
  persistent: false,
  btnText: '確定',
  btnCancelText: '',
  btnDonFun: () => { },
  btn: [],
  clickOutside: () => { },
  isLoading: false,
  noBtn: false
})

export const getters = {
}

export const mutations = {
  toggle (state, status) {
    state.open = status
  },
  setAlert (state, dialog) {
    Object.entries(dialog).map(([key, value]) => {
      state[key] = value
    })
  },
  clear (state) {
    state.open = false
    state.type = 'normal'
    state.title = ''
    state.message = ''
    state.messageHTML = ''
    state.persistent = false
    state.btnText = '確定'
    state.btnCancelText = ''
    state.btn = []
    state.isLoading = false
    state.noBtn = false
    state.btnDonFun = () => { }
    state.clickOutside = () => {}
  },
  setDonFun (state, btnDonFun) {
    state.btnDonFun = btnDonFun
  }
}

export const actions = {
  open ({ commit }) {
    commit('toggle', true)
  },
  async close ({ commit }, delayTime = 400) {
    return new Promise((resolve, reject) => {
      commit('toggle', false)
      setTimeout(() => {
        commit('clear')
      }, delayTime > 300 ? 300 : delayTime)
      setTimeout(() => {
        resolve()
      }, delayTime)
    })
  }
}
