/**
 * 資料驗證規則統整
 */

export default (context, inject) => {
  /**
   * 驗證Email
   *
   * @param {string} value 欲驗證的mail
   */
  const checkEmailFormat = (value) => {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return pattern.test(value)
  }

  inject('checkEmailFormat', checkEmailFormat)

  context.$checkEmailFormat = checkEmailFormat
}
