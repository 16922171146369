import { render, staticRenderFns } from "./_index.vue?vue&type=template&id=1f0e768c&scoped=true&"
import script from "./_index.vue?vue&type=script&lang=js&"
export * from "./_index.vue?vue&type=script&lang=js&"
import style0 from "./_index.vue?vue&type=style&index=0&id=1f0e768c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f0e768c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems})
