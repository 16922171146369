export const state = () => ({
  orderList: {
    allOrderList: [],
    orderStatusList_1: [],
    orderStatusList_2: [],
    orderStatusList_3: [],
    orderStatusList_4: [],
    notice: {
      status1: false,
      status2: false,
      status3: false,
      status4: false
    }
  },
  status: {
    allLoadDown: false,
    firstDataDown: false,
    stop: false
  },
  order: {},
  completedOrderList: [],
  hasOrderIsQuotedAndUnread: false,
  openProgressBarFromList: false,
  orderInfoDialog: {
    orderId: null,
    open: false,
    title: '',
    message: '',
    persistent: false,
    btn: []
  }
})

export const getters = {
}

export const mutations = {
  loadingToggle (state, { key, status }) {
    state.loading[key] = status
  },
  toggleStatus (state, { key, status }) {
    state.status[key] = status
  },
  clearOrderList (state, Data) {
    state.orderList.allOrderList = _.cloneDeep(Data)
    state.orderList.orderStatusList_1 = _.cloneDeep(Data)
    state.orderList.orderStatusList_2 = _.cloneDeep(Data)
    state.orderList.orderStatusList_3 = _.cloneDeep(Data)
    state.orderList.orderStatusList_4 = _.cloneDeep(Data)
  },
  pushOrderList (state, { Data }) {
    Data.map(order => {
      if ([1, 6, 7, 8, 10].indexOf(order.process_id) > -1 && order.PaymentStatus.CanPay) {
        state.orderList.orderStatusList_1.push(order)
        if (order.IsQuotedPrice) {
          state.orderList.notice.status1 = true
        }
      }
      if ([1, 6, 7, 8].indexOf(order.process_id) > -1) {
        state.orderList.orderStatusList_2.push(order)
        if (order.IsQuotedPrice) {
          state.orderList.notice.status2 = true
        }
      }
      if (order.process_id === 10) {
        state.orderList.orderStatusList_3.push(order)
        if (order.IsQuotedPrice) {
          state.orderList.notice.status3 = true
        }
      }
      if ([2, 3, 4, 5, 9].indexOf(order.process_id) > -1) {
        state.orderList.orderStatusList_4.push(order)
        // 已完成 && 未讀
        if ([2, 9].indexOf(order.process_id) > -1) {
          state.completedOrderList.push(order)
        }
      }
      state.orderList.allOrderList.push(order)
    })
  },
  toggleGetOrderStop (state, status) {
    state.status.stop = status
  },
  setOrderData (state, { id, Data }) {
    if (Data) {
      const order = _.cloneDeep(state.order)
      order[parseInt(id)] = _.cloneDeep(Data)
      state.order = _.cloneDeep(order)
    } else {
      delete state.order[parseInt(id)]
    }
  },
  checkCompletedOrderAllRead (state) {
    const allRead = state.completedOrderList.every(order => order.completed_order_is_read === true)
    if (allRead) {
      state.orderList.notice.status4 = false
      state.hasOrderIsQuotedAndUnread = false
    } else {
      state.orderList.notice.status4 = true
      state.hasOrderIsQuotedAndUnread = true
    }
  },
  checkNotCompletedOrderIsQuoted (state) {
    const isQuoted1 = state.orderList.orderStatusList_1.some(order => order.IsQuotedPrice)
    isQuoted1 ? state.orderList.notice.status1 = true : state.orderList.notice.status1 = false
    const isQuoted2 = state.orderList.orderStatusList_2.some(order => order.IsQuotedPrice)
    isQuoted2 ? state.orderList.notice.status2 = true : state.orderList.notice.status2 = false
    const isQuoted3 = state.orderList.orderStatusList_3.some(order => order.IsQuotedPrice)
    isQuoted3 ? state.orderList.notice.status3 = true : state.orderList.notice.status3 = false
    isQuoted1 || isQuoted2 || isQuoted3 ? state.hasOrderIsQuotedAndUnread = true : state.hasOrderIsQuotedAndUnread = false
  },
  setCompletedOrderRead (state, data) {
    state.completedOrderList[data].completed_order_is_read = true
  },
  setOrderIsQuotedAndUnread (state, data) {
    state.hasOrderIsQuotedAndUnread = data
  },
  clearOrderIsQuotedAndUnread (state) {
    state.hasOrderIsQuotedAndUnread = null
    state.orderList.notice = {
      status1: false,
      status2: false,
      status3: false,
      status4: false
    }
  },
  setOrderInfoDialog (state, data) {
    state.orderInfoDialog = { ...data }
  },
  resetOrderInfoDialog (state) {
    state.orderInfoDialog = {
      type: 'normal',
      open: false,
      title: '',
      message: '',
      persistent: false,
      btn: []
    }
  },
  setOpenProgressBarFromList (state, data) {
    state.openProgressBarFromList = data
  }
}

export const actions = {
  async getOrderListByPage ({ commit, state, dispatch }, page) {
    const prePageCount = 30
    const { Data } = await this.$axios.$get('/api/v2/Order/GetOrderInfo', { params: { page, rows: prePageCount } })
    commit('pushOrderList', { Data: Data.Orders })
    if (Data.Orders.length < prePageCount) {
      commit('toggleStatus', { key: 'allLoadDown', status: true })
      return // 如果撈完了，就停止撈取
    }
    if (state.status.stop) {
      commit('toggleStatus', { key: 'allLoadDown', status: false })
      return
    }
    if (!state.status.firstDataDown) {
      commit('toggleStatus', { key: 'firstDataDown', status: true })
    }
    await dispatch('getOrderListByPage', page + 1)
  },
  async getOrderList ({ commit, state, dispatch }) {
    console.log('getOrderList')
    // if (state.status.allLoadDown) return // 全載完，不再載一次
    commit('toggleStatus', { key: 'allLoadDown', status: false })
    commit('toggleStatus', { key: 'firstDataDown', status: false })
    commit('toggleStatus', { key: 'stop', status: false })
    commit('clearOrderList', [])
    await dispatch('getOrderListByPage', 1)
    commit('checkCompletedOrderAllRead')
    commit('checkNotCompletedOrderIsQuoted')
  },
  async getOrderInfo ({ commit, state, dispatch }, { id }) {
    if (!id) return
    // if (state.order[parseInt(id)]) return state.order[parseInt(id)]
    const { data } = await this.$axios.get('/api/v2/Order/GetOrderDetailsInfo', { params: { orderId: id } })
      .catch(error => Promise.reject(error))
    commit('setOrderData', { id, Data: data.Data })
    return data.Data
  },
  async getOrderInfoByHashCode ({ commit, state, dispatch }, { HashCode }) {
    if (!HashCode) return Promise.reject('no HashCode')
    const { data } = await this.$axios.get('/api/v2/Order/GetOrderInfoByHashCode', { params: { hashCode: HashCode } })
    return data.Data
  },
  async checkHasOrderIsQuotedAndUnread ({ commit }) {
    try {
      const postData = {
        AccountUid: this.$auth.user.AUid
      }
      const { data } = await this.$axios.get('/api/Order/CheckServiceAccountOrdersHasNotReadAndQuotedPrice', { params: postData })
      commit('setOrderIsQuotedAndUnread', data.Data)
    } catch (error) {
      console.log('error', error)
      commit('setOrderIsQuotedAndUnread', null)
    }
  }
}
