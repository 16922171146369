import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

const errorImg = require('@/assets/image/serviceIntro/cantdisplay-image@3x.png')
const loadingImg = require('@/assets/image/serviceIntro/loading-state-image@3x.png')

Vue.use(VueLazyload, {
  error: errorImg,
  loading: loadingImg,
  attempt: 1
})
