import AOS from 'aos'

import 'aos/dist/aos.css'

export default ({ app }) => {
  app.AOS = new AOS.init({
    offset: 50, // offset (in px) from the original trigger point
    once: true, // whether animation should happen only once - while scrolling down
    duration: 500,
    startEvent: 'DOMContentLoaded',
    anchorPlacement: 'top-bottom'
  })
}
