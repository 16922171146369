import qs from 'qs'
import { cacheAdapterEnhancer } from 'axios-extensions'
import LRUCache from 'lru-cache'

const cacheConfig = new LRUCache({
  // 有效期2mins
  ttl: 1000 * 60 * 2,
  // 最大缓存数量
  max: 1000
})

export default function ({ $axios, store, app }, inject) {
  const { CancelToken } = $axios
  const onRequest = config => {
    config.cancelToken = new CancelToken((cancel) => {
      store.dispatch('axiosPending/pushAxiosPending', JSON.stringify({ cancel, config }))
    })

    const {
      tracing,
      tracingHeaderValue,
      tracingHeaderKey
    } = store.state.sentryConfig
    if (tracing && tracingHeaderValue) {
      config.headers[tracingHeaderKey] = tracingHeaderValue
    }

    return config
  }
  const onResponse = response => {
    // 移除 pending
    store.dispatch('axiosPending/removeAxiosPending', response.config)
    return response
  }

  // Authorization
  $axios.setHeader('Content-Type', 'application/json')

  $axios.defaults.paramsSerializer = params => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
  const defaultAdapter = $axios.defaults.adapter
  $axios.defaults.adapter = cacheAdapterEnhancer(defaultAdapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
    defaultCache: cacheConfig
  })

  $axios.setBaseURL(process.env.API_ECS_BASE_URL)
  $axios.onRequest(onRequest) // API攔截器（送出前）
  $axios.onResponse(onResponse) // API攔截器（送出後）

  // PlusPay 用 domain API
  const staticEcsApi = $axios.create({
    headers: { 'Content-Type': 'application/json' }
  })
  staticEcsApi.setBaseURL(process.env.API_STATIC_ECS_BASE_URL)
  staticEcsApi.onRequest(config => {
    if (app.$auth.loggedIn && !config.headers.common.Authorization) {
      // 若已登入但未設定到 token(Authorization)
      staticEcsApi.setHeader('Authorization', app.$auth.getToken('local'))
      config.headers.common.Authorization = app.$auth.getToken('local')
    }
    onRequest(config)
  }) // API攔截器（送出前）
  staticEcsApi.onResponse(onResponse) // API攔截器（送出後）
  inject('staticEcsApi', staticEcsApi)

  // Nuxt Node.js 用 domain API
  const serviceApi = $axios.create({
    headers: { 'Content-Type': 'application/json' }
  })
  serviceApi.setBaseURL('') // 不需 baseUrl
  serviceApi.onRequest(config => {
    onRequest(config)
  }) // API攔截器（送出前）
  serviceApi.onResponse(onResponse) // API攔截器（送出後）
  inject('serviceApi', serviceApi)

  // 會員整合 用 domain API
  const serviceAccountSetApi = $axios.create({
    headers: { 'Content-Type': 'application/json' }
  })
  serviceAccountSetApi.setBaseURL(process.env.API_SERVICE_ACCOUNT_SET_BASE_URL)
  serviceAccountSetApi.onRequest(config => {
    if (app.$auth.loggedIn && !config.headers.common.Authorization) {
      // 若已登入但未設定到 token(Authorization)
      serviceAccountSetApi.setHeader('Authorization', app.$auth.getToken('local'))
      config.headers.common.Authorization = app.$auth.getToken('local')
    }
    onRequest(config)
  }) // API攔截器（送出前）
  serviceAccountSetApi.onResponse(onResponse) // API攔截器（送出後）
  inject('serviceAccountSetApi', serviceAccountSetApi)
}
