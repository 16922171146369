<template>
  <v-app>
    <div
      class="body"
      :class="`page-${$route.name}`"
    >
      <component-header />
      <component-toast />
      <component-alert />
      <compoment-tooltip />
      <component-image-browser />
      <component-register-success />
      <group-banner-list
        v-if="false"
        v-show="hasBanner"
        :has-banner="hasBanner"
      />

      <nuxt class="layout-page" />

      <v-dialog
        v-model="alertDialog.open"
        persistent
        width="500"
      >
        <v-card id="default-alert-dialog">
          <div
            v-if="alertDialog.loading"
            class="loading"
          >
            <p>載入中</p>
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>
          <template v-else>
            <v-card-title
              v-show="alertDialog.title"
              class="headline"
            >
              {{ alertDialog.title }}
            </v-card-title>
            <v-card-text v-show="alertDialog.message">
              {{ alertDialog.message }}
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-for="(btn, index) in alertDialog.btn"
                :key="index"
                :color="btn.btnColor"
                :outlined="btn.outlined"
                :depressed="btn.depressed"
                :loading="btn.btnIsLoading"
                @click="btn.btnClick"
              >
                {{ btn.btnText }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>

      <ButtonLineChat />
      <ButtonBackToTop />
      <component-footer v-show="showFooter" />
      <div
        class="cookies-accept"
        :class="{'show': cookieAcceptNotYet}"
      >
        <div class="content container">
          <p>
            本網站使用cookies以提昇您的使用體驗及統計網路流量相關資料。繼續使用本網站表示您同意我們使用cookies。了解我們的<nuxt-link :to="{name: 'privacy'}">
              隱私權政策
            </nuxt-link>。
          </p>
          <v-btn
            class="btn"
            outlined
            @click="cookiesAccept"
          >
            同意
          </v-btn>
        </div>
      </div>
    </div>
    <PopupMemberUpgrade
      :open="popupMemberUpgradeOpen"
      @memberUpgradeAction="memberUpgradeActionHandler"
    />
  </v-app>
</template>

<script>
import ButtonLineChat from '@/components/Button/ButtonLineChat.vue'
import ButtonBackToTop from '@/components/Button/ButtonBackToTop.vue'
import ComponentHeader from '@/components/ComponentHeader.vue'
import ComponentFooter from '@/components/ComponentFooter.vue'
import GroupBannerList from '@/components/GroupBannerList.vue'
import ComponentToast from '@/components/ComponentToast.vue'
import ComponentImageBrowser from '@/components/ComponentImageBrowser.vue'
import ComponentRegisterSuccess from '@/components/ComponentRegisterSuccess.vue'
import ComponentAlert from '@/components/CompomentAlert'
import PopupMemberUpgrade from '@/components/Popup/PopupMemberUpgrade'
import '@/assets/icomoon/style.css'
import CompomentTooltip from '@/components/CompomentTooltip'
import { firebaseSmartlife } from '~/plugins/firebase'
export default {
  name: 'Default',
  components: {
    'component-header': ComponentHeader,
    'component-footer': ComponentFooter,
    'component-toast': ComponentToast,
    ComponentImageBrowser,
    GroupBannerList,
    ComponentRegisterSuccess,
    ComponentAlert,
    PopupMemberUpgrade,
    CompomentTooltip,
    ButtonLineChat,
    ButtonBackToTop
  },
  middleware: 'stats',
  data () {
    return {
      isMobile: null,
      hasBanner: false,
      isInit: false,
      isSignIn: false,
      checkGoogleInitNum: 0,
      cookieAcceptNotYet: null,
      alertDialog: {
        open: false,
        title: '',
        message: '',
        btnText: '',
        loading: false,
        toClear: false,
        btn: [{
          btnText: '關閉',
          btnColor: '',
          btnIsLoading: false,
          btnClick: () => {}
        }]
      },
      pageFirebaseAlert: {
        open: false,
        // read: false,
        pages: [],
        alert: {},
        alertList: []
      },
      upgradeAlertTimeout: null,
      popupMemberUpgradeOpen: false,
      uncheckQuotedOrders: {
        Id: null
      }
    }
  },
  head () {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: String(process.env.BASE_WEB_URL || ' ').slice(0, -1) + this.$route.path
        }
      ]
    }
  },
  computed: {
    showFooter () {
      const showFooterPageName = ['home', 'index', 'index-id', 'informationStation', 'informationStation-article-idsn', 'star']
      if (!this.$nuxt.$route || !this.$nuxt.$route.name) return false
      return !(this.isMobile && !showFooterPageName.includes(this.$nuxt.$route.name)) && !this.$nuxt.nuxt.err
    },
    KTJNoPickUpOrders () {
      return this.$store.state.multiplePackage.KTJNoPickUpOrders
    }
  },
  watch: {
    async '$route' (val, old) {
      this.checkFirebaseAlertList(val.name)
      this.$sentry.setTag('pageName', this.$nuxt.$route.name)
      this.$nextTick(() => {
        this.FBConversionAPI(val)
        // this.setBannerShow()
        this.checkQuery()
        this.checkKTJNoPickUpOrders()
        this.checkHasQuotedOrder()
        this.$store.dispatch('getAppversionFromFirebase')
      })
    },
    KTJNoPickUpOrders (val, oldVal) {
      if (oldVal === null) return
      this.checkKTJNoPickUpOrders()
    },
    '$auth.loggedIn' (val) {
      if (!val) {
        clearTimeout(this.upgradeAlertTimeout)
        window.localStorage.setItem(
          'memberUpgradeAlertInfo',
          JSON.stringify({
            time: null,
            open: false
          })
        )
        this.$sentry.setTag('phone', null)
      } else {
        this.$sentry.setTag('phone', this.$auth.user.APhone)
      }
      this.$sentrySetUser()
    }
  },
  async mounted () {
    console.log('version: ', $nuxt.context.env.version, 'APP_SUBVERSION: ', process.env.APP_SUBVERSION)
    await this.checkFirebase()
    if (process.client) {
      this.setWindowWidth()
      window.addEventListener('resize', _.debounce(this.setWindowWidth, 600))
    }
    this.$store.dispatch('getAppversionFromFirebase')
    // await this.checkFirebaseAlert(this.$nuxt.$route.name)
    // if (this.$auth.loggedIn && this.$auth.user.Type === 1) {   // Google 登入
    //   await this.checkGoogleInit()
    //   const GoogleUser = await this.$gAuth.GoogleAuth.currentUser.get()
    //   const profile = await GoogleUser.getBasicProfile()
    //   this.$auth.$storage.setState('googleEmail', profile.getEmail())
    // }
    this.cookieAcceptNotYet = !this.$auth.$storage.getCookie('userAcceptCookie')
    if (process.client && this.$auth.loggedIn && this.$auth.user && this.$auth.user.Type === 1) {
      // Google 登入
      if (!this.$auth.$storage.getLocalStorage('googleToken')) {
        await this.$store.dispatch('logOut')
      }
      this.$axios.get('https://www.googleapis.com/oauth2/v1/userinfo', { params: { access_token: this.$auth.$storage.getLocalStorage('googleToken') } })
        .then(res => {
          if (res.data.email) {
            this.$auth.$storage.setState('googleEmail', res.data.email)
          }
        })
    }
    this.isMobile = screen.width <= 768
    this.setSize()
    this.checkQuery()
    // this.setBannerShow()
    this.checkHasQuotedOrder()
    await this.$nextTick()
    this.$sentrySetUser()
    if (this.$auth.loggedIn) {
      this.$sentry.setTag('phone', this.$auth.user.APhone)
    }
    await this.$awaitSetTimeout(300)
    this.$nuxt.$loading.finish()
    if (this.$nuxt.$route.name !== 'serviceMultiplePackage') {
      if (this.$auth.loggedIn && this.$store.state.multiplePackage.KTJNoPickUpOrders === null) {
        await this.$store.dispatch('multiplePackage/getHasKTJNoPickUpOrders')
      }
      this.checkKTJNoPickUpOrders()
    }
    if (!this.$store.getters.isAgentOrder) {
      if (this.$auth.loggedIn && this.$auth?.user?.Type !== 3) {
        this.showThirdLoginAlert()
      } else {
        await this.checkMemberUpgrade()
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.setWindowWidth, 200)
  },
  methods: {
    FBConversionAPI (routeData) {
      if (process.server) return
      const {
        ABirthday,
        AGender,
        APhone,
        AEmail,
        AName
      } = this.$auth.user || {}
      this.$FBConversionAPI({
        event_name: 'ViewContent',
        user_data: {
          first_name: this.$auth.loggedIn ? AName : 'guest',
          date_birth: ABirthday || '',
          gender: AGender || '',
          phone: APhone || '',
          email: AEmail || ''
        },
        custom_data: {
          pageName: routeData.name,
          pageParams: routeData.params,
          pageQuery: routeData.query
        }
      })
    },
    setSize () {
      const setVh = () => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
      const setVW = () => {
        const vw = window.innerWidth * 0.01
        document.documentElement.style.setProperty('--vw', `${vw}px`)
      }
      setVh()
      setVW()
      window.addEventListener('resize', () => {
        setVh()
        setVW()
      })
    },
    setWindowWidth () {
      this.$store.commit('setWindowWidth', window.innerWidth)
    },
    async checkQuery () {
      const query = Object.keys(this.$nuxt.$route.query)

      // 領取優惠券
      const ticketQuery = query.filter(key => key.indexOf('Ticket_GET') > -1)
      if (ticketQuery.length > 0) {
        this.alertDialog.loading = true
        this.alertDialog.open = true

        const ticketGuid = this.$nuxt.$route.query[ticketQuery[0]]
        const guid = typeof ticketGuid === 'string' ? ticketGuid : ticketGuid[0]
        const { data } = await this.$axios.get('/api/Gift/GetOfficialGiftByGUID', { params: { Guid: guid } })
          .catch(error => {
            let errorCode = null
            let errorTitle = ''
            let errorMessage = ''
            if (error.response &&
                error.response.data &&
                error.response.data.ErrorMessage &&
                error.response.data.ErrorMessage.Code) {
              errorCode = error.response.data.ErrorMessage.Code
            }
            if (errorCode === null) {
              this.alertDialog.title = '領取失敗'
              this.alertDialog.message = '禮物領取失敗'
              this.alertDialog.btn = [{
                btnText: '確定',
                btnColor: 'primary',
                btnIsLoading: false,
                btnClick: () => {
                  this.clearTicketQuery()
                  this.alertDialog.open = false
                }
              }]
              this.alertDialog.loading = false
              return Promise.reject()
            }
            if (errorCode === 401000) { // 登入過期或驗證失敗，請重新登入
              this.alertDialog.title = '請登入或註冊會員'
              this.alertDialog.message = '您必須是會員才能接收禮物 ！'
              this.alertDialog.btn = [
                {
                  btnText: '會員驗證',
                  btnColor: 'primary',
                  btnIsLoading: false,
                  btnClick: () => {
                    this.$auth.$storage.setState('toRoute', {
                      name: this.$nuxt.$route.name,
                      params: this.$nuxt.$route.params,
                      query: this.$nuxt.$route.query
                    })
                    this.$nuxt.$router.push({ name: 'login' })
                    this.alertDialog.open = false
                  }
                },
                {
                  btnText: '放棄禮物',
                  btnColor: 'primary',
                  btnIsLoading: false,
                  btnClick: () => {
                    this.clearTicketQuery()
                    this.alertDialog.open = false
                  }
                }
              ]
              this.alertDialog.loading = false
              return Promise.reject()
            }
            if (errorCode === 411006) {
              errorTitle = '禮物已被領走'
              errorMessage = '慢一步了，禮物已經被領走囉'
            } else if (errorCode === 411003) {
              errorTitle = '尚未開始領取'
              errorMessage = '禮物尚未開放領取，請等待至指定時間再領取'
            } else if (errorCode !== null) {
              errorTitle = '已截止領取'
              errorMessage = '禮物已經停止領取囉，下回請早'
            }
            this.alertDialog.title = errorTitle
            this.alertDialog.message = errorMessage
            this.alertDialog.btn = [{
              btnText: '關閉',
              btnColor: 'primary',
              btnIsLoading: false,
              btnClick: () => {
                this.clearTicketQuery()
                this.alertDialog.open = false
              }
            }]
            this.alertDialog.loading = false
            return Promise.reject()
          })
        // 目前只允許官方贈禮 單一模式 (智生活App 2.8.0)
        const gift = data.Data[0]
        if ((gift.GiftTypeId === 1 &&
            (gift.GiftTicketMode.Id === 1 || gift.GiftTicketMode.Id === 2)
        ) ||
           gift.GiftTypeId === 2) {
          if (gift.ListTicket.length === 1) {
            const ticketData = gift.ListTicket[0]
            // 票券效期為10分鐘 => 銘謝惠顧
            const ValidDue = ticketData.ValidDue.replace(/-/g, '/').replace('T', ' ')
            const ValidStart = ticketData.ValidStart.replace(/-/g, '/').replace('T', ' ')
            if (this.$dayjs(new Date(ValidDue)).diff(this.$dayjs(new Date(ValidStart)), 'd') === 10) {
              this.alertDialog.title = '銘謝惠顧'
              this.alertDialog.message = '真可惜，再接再厲！'
              this.alertDialog.btn = [
                {
                  btnText: '確定',
                  btnColor: 'primary',
                  btnIsLoading: false,
                  btnClick: () => {
                    this.clearTicketQuery()
                    this.alertDialog.open = false
                  }
                }
              ]
              this.alertDialog.loading = false
              return Promise.resolve()
            }
          }
          this.alertDialog.title = '拆禮物'
          this.alertDialog.message = '智生活送你一個禮物'
          this.alertDialog.btn = [
            {
              btnText: '立即接收',
              btnColor: 'primary',
              btnIsLoading: false,
              btnClick: () => {
                const ticketIdList = gift.ListTicket.map(ticket => ticket.Id)
                this.getGift(guid, ticketIdList)
              }
            }
          ]
          this.alertDialog.loading = false
        }
      }
    },
    showThirdLoginAlert () {
      this.$alert({
        title: '社群會員結束服務',
        messageHTML: '為提升您的會員資料安全保障，同時提供更優質的會員權益，平台已全面進行會員改版。<br />請使用手機帳號登入/註冊以連結社群帳號啟用快速登入，或透過已連結的社群帳號快速登入',
        persistent: true,
        btn: [
          {
            btnText: '確認',
            btnColor: 'primary',
            btnClick: async () => {
              await this.$store.dispatch('logOut')
              this.$store.commit('order/clearOrderIsQuotedAndUnread')
              this.$store.commit('multiplePackage/updateKTJNoPickUpOrders', null)
              this.$nuxt.$router.push({ name: 'login' })
              this.$alert.close()
            }
          }
        ]
      })
    },
    async checkMemberUpgrade () {
      try {
        if (!this.$auth.loggedIn) return
        if (!('IsServiceAccountTransferEnd' in this.$auth.user)) {
          await this.$auth.fetchUser()
        }
        const isUpgradedMember = this.$auth.user.IsServiceAccountTransferEnd
        const lastUpgradeInfo = JSON.parse(window.localStorage.getItem('memberUpgradeAlertInfo'))
        if (lastUpgradeInfo?.open === false) return
        if (isUpgradedMember) return
        const needShowAlertTime = lastUpgradeInfo
          ? this.$dayjs(lastUpgradeInfo.time).add(7, 'day')
          : this.$dayjs()
        const isNeedShowAlert = this.$dayjs().isAfter(needShowAlertTime)
        if (isNeedShowAlert || !lastUpgradeInfo) {
          await this.showUpgradeAlert()
        } else if (this.$dayjs().isBefore(needShowAlertTime)) {
          const timeToShowAlert = needShowAlertTime.diff(this.$dayjs())
          this.setUpgradeAlertTimeout(timeToShowAlert)
        }
      } catch (err) {
        console.log('err', err)
      }
    },
    goUpgrade () {
      if (this.$device.isMobileOrTablet) {
        window.open('https://community.kingnetsmart.com.tw/T/launchApp.html', '_blank')
      } else {
        sessionStorage.setItem(
          'memberUpgradeSaveRoute',
          JSON.stringify(
            {
              routeObj: {
                name: this.$nuxt.$route.name,
                params: this.$nuxt.$route.params
              },
              redirect: false
            }
          )
        )
        this.$nuxt.$router.push({ name: 'memberUpgrade' })
      }
    },
    async showUpgradeAlert (updateUserInfo) {
      try {
        if (updateUserInfo) {
          await this.$auth.fetchUser()
        }
        if (this.$auth.user.IsServiceAccountTransferEnd) return
        this.popupMemberUpgradeOpen = true
      } catch (err) {
        console.log('err', err)
      }
    },
    memberUpgradeActionHandler (action) {
      this.popupMemberUpgradeOpen = false
      if (action === 'RemindNextTime') {
        this.setUpgradeAlertTimeout(7 * 24 * 60 * 60 * 1000)
        window.localStorage.setItem(
          'memberUpgradeAlertInfo',
          JSON.stringify({
            time: this.$dayjs(),
            open: true
          })
        )
      } else if (action === 'UpgradeNow') {
        this.goUpgrade()
        window.localStorage.setItem(
          'memberUpgradeAlertInfo',
          JSON.stringify({
            time: null,
            open: false
          })
        )
      }
    },
    setUpgradeAlertTimeout (millisecond) {
      clearTimeout(this.upgradeAlertTimeout)
      this.upgradeAlertTimeout = setTimeout(async () => {
        this.showUpgradeAlert(true)
      }, millisecond)
    },
    async getGift (guid, ticketIdList) {
      this.alertDialog.loading = true
      const { data } = await this.$axios.post('/api/Gift/ExchangeOfficialGift', {
        Guid: guid,
        ListTicketId: ticketIdList
      })
        .catch(error => {
          let errorCode = null
          if (error.response &&
              error.response.data &&
              error.response.data.ErrorMessage &&
              error.response.data.ErrorMessage.Code) {
            errorCode = error.response.data.ErrorMessage.Code
          }
          if (errorCode === 411005) {
            this.alertDialog.title = '您已領過囉'
            this.alertDialog.message = '您已領取過這個禮物，不能再領取囉'
            this.alertDialog.btn = [{
              btnText: '關閉',
              btnColor: 'primary',
              btnIsLoading: false,
              btnClick: () => {
                this.clearTicketQuery()
                this.alertDialog.open = false
              }
            }]
            this.alertDialog.loading = false
          } else {
            this.alertDialog.title = '領取失敗'
            this.alertDialog.message = '禮物領取失敗'
            this.alertDialog.btn = [{
              btnText: '確定',
              btnColor: 'primary',
              btnIsLoading: false,
              btnClick: () => {
                this.clearTicketQuery()
                this.alertDialog.open = false
              }
            }]
            this.alertDialog.loading = false
          }
          return Promise.reject()
        })
      if (data.Data[0].GiftType.Id === 1) {
        // 優惠券
        let TicketTitle = ''
        data.Data[0].ListTicket.map(ticket => {
          TicketTitle += `【${ticket.Name}】`
        })
        this.alertDialog.title = '真是個好禮物'
        this.alertDialog.message = `您獲得了${TicketTitle}優惠券！優惠券已存入您的帳戶`
        this.alertDialog.btn = [
          {
            btnText: '前往我的優惠券',
            btnColor: 'primary',
            btnIsLoading: false,
            btnClick: () => {
              // TODO: 在優惠券領取 => 觸發優惠券更新
              this.$nuxt.$router.push({ name: 'member-ticket', params: { toTop: true } })
              this.alertDialog.open = false
            }
          },
          {
            btnText: '我知道了',
            btnColor: 'primary',
            btnIsLoading: false,
            btnClick: () => {
              this.clearTicketQuery()
              this.alertDialog.open = false
            }
          }
        ]
        this.alertDialog.loading = false
      } else if (data.Data[0].GiftType.Id === 2) {
        // 紅利點數
        this.alertDialog.title = '真是個好禮物'
        this.alertDialog.message = `您獲得了【紅利點數${data.Data[0].GiftBonus}點】！點數已存入您的帳戶`
        this.alertDialog.btn = [
          {
            btnText: '前往紅利點數',
            btnColor: 'primary',
            btnIsLoading: false,
            btnClick: () => {
              // TODO: 在紅利點數領取 => 觸發紅利點數更新
              this.$nuxt.$router.push({ name: 'member-bonus-listGet', params: { toTop: true } })
              this.alertDialog.open = false
            }
          },
          {
            btnText: '我知道了',
            btnColor: 'primary',
            btnIsLoading: false,
            btnClick: () => {
              this.clearTicketQuery()
              this.alertDialog.open = false
            }
          }
        ]
        this.alertDialog.loading = false
      }
    },
    async checkGoogleInit () {
      const self = this
      return new Promise(function (resolve, reject) {
        const checkGauthLoad = setInterval(function () {
          self.isInit = self.$gAuth.isInit
          self.isSignIn = self.$gAuth.isAuthorized
          self.checkGoogleInitNum += 1
          if (self.checkGoogleInitNum >= 100) {
            clearInterval(checkGauthLoad)
            self.checkGoogleInitNum = 0
            resolve()
          }
          if (self.isInit) {
            clearInterval(checkGauthLoad)
            resolve()
          }
        }, 1000)
      })
    },
    setBannerShow () {
      const nobanner = _.find(this.$nuxt.context.route.meta, { banner: false })
      const noMobileBanner = _.find(this.$nuxt.context.route.meta, { mobileBanner: false })
      if (nobanner) {
        this.hasBanner = false
      } else if (noMobileBanner && this.isMobile) {
        this.hasBanner = false
      } else {
        this.hasBanner = true
      }
      if (this.$nuxt.nuxt.err) {
        this.hasBanner = false
      }
    },
    clearTicketQuery () {
      const query = { ...this.$nuxt.$route.query }
      delete query['Ticket_GET?Guid']
      this.$nuxt.$router.replace({
        name: this.$nuxt.$route.name,
        params: this.$nuxt.$route.params,
        query
      })
      this.alertDialog.toClear = false
    },
    cookiesAccept () {
      this.cookieAcceptNotYet = false
      this.$auth.$storage.setCookie('userAcceptCookie', true, { expires: 365 }) // 365天
    },
    async checkKTJNoPickUpOrders () {
      if (process.server) return
      if (!this.$auth.loggedIn) {
        // 未登入
        if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'KTJNoPickUpOrders') {
          this.$tooltip.close()
        }
        return Promise.resolve()
      }
      if (this.KTJNoPickUpOrders === null) return Promise.resolve()
      try {
        if (['payment-idHashCodeSn', // 付款頁
          'logistics-idsn', // 託運單號（一般）
          'logistics-multiplePackage-idsn', // 託運單號 (多包裹)
          'serviceMultiplePackage' // 多包裹頁面
        ].indexOf(this.$nuxt.$route.name) > -1) {
          // 部分頁面不顯示
          throw false
        }
        if (this.$nuxt.$route.name === 'member-order-idsn' &&
            this.$nuxt.$route.params) {
          // 確認已在未交寄的訂單詳情頁面
          const checkIncludeOrder = this.KTJNoPickUpOrders.find(order => {
            return order.OrderId === parseInt(this.$nuxt.$route.params.idsn.split('-')[0]) &&
                    order.OrderSn === this.$nuxt.$route.params.idsn.split('-')[1]
          })
          if (checkIncludeOrder) {
            throw false
          }
        }
        if (this.KTJNoPickUpOrders.length === 0) {
          if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'KTJNoPickUpOrders') {
            this.$tooltip.close()
          }
          return Promise.resolve()
        }
        if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'KTJNoPickUpOrders') {
          return Promise.resolve()
        }
        this.$tooltip({
          id: 'KTJNoPickUpOrders',
          title: '系統訊息',
          message: `提醒您，目前有 ${this.KTJNoPickUpOrders.length} 筆訂單尚未通知物流取件，請儘速將包裹放置管理室後回報。`,
          btnText: '前往通知物流',
          icon: 'icon-alert-circle-full',
          color: 'info',
          targets: '#header menu li:nth-child(6)',
          top: true,
          maxWidth: 400,
          sticky: 'reference',
          canClose: true,
          options: {
            popperOptions: {
              strategy: 'fixed'
            }
          },
          donFun: () => {
            this.$nuxt.$router.push({
              name: 'member-order-idsn',
              params: { idsn: `${this.KTJNoPickUpOrders[0].OrderId}-${this.KTJNoPickUpOrders[0].OrderSn}` }
            })
            this.$tooltip.close()
          }
        })
      } catch (error) {
        if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'KTJNoPickUpOrders') {
          await this.$nextTick()
          await this.$awaitSetTimeout(100)
          this.$tooltip.close()
        }
      }
    },
    async checkHasQuotedOrder () {
      if (process.server) return
      if (!this.$auth.loggedIn) {
        // 未登入
        if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'hasUncheckQuotedOrder') {
          this.$tooltip.close()
        }
        return Promise.resolve()
      }
      if (['payment-idHashCodeSn'].indexOf(this.$nuxt.$route.name) > -1) {
        // 部分頁面不顯示
        return false
      }
      try {
        await this.getUncheckQuotedOrder()
        const isInOrderOrOrderQuotataionPage = ['member-order-idsn', 'member-order-quotationInfo-idsn'].includes(this.$nuxt.$route.name)
        if (isInOrderOrOrderQuotataionPage && this.$nuxt.$route.params) {
          const { idsn: orderId } = this.$nuxt.$route.params
          const isInUncheckOrderPage = parseInt(orderId) === parseInt(this.uncheckQuotedOrders.Id)
          if (isInUncheckOrderPage) {
            if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'hasUncheckQuotedOrder') {
              this.$tooltip.close()
            }
            return false
          }
        }
        if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'hasUncheckQuotedOrder') {
          return Promise.resolve()
        }
        if (this.uncheckQuotedOrders.Id) this.showQuotationTooltip()
      } catch (error) {
        if (this.$store.state.tooltip.open && this.$store.state.tooltip.id === 'hasUncheckQuotedOrder') {
          await this.$nextTick()
          await this.$awaitSetTimeout(100)
          this.$tooltip.close()
        }
      }
    },
    async checkFirebase () {
      const messageRef = firebaseSmartlife.database().ref('ecweb/Notification')
      return new Promise((resolve, reject) => {
        try {
          messageRef.on('value', snapshot => {
            if (!snapshot.exists()) {
              return resolve()
            }
            const alertList = snapshot.val().alert
              .filter(alert => alert.open && (alert.title || alert.message))
              .sort((a, b) => a.order - b.order)
            this.$set(this.pageFirebaseAlert, 'alertList', alertList)
            this.checkFirebaseAlertList(this.$nuxt.$route.name)
              .then(() => resolve())

            // if (alert.open && (alert.title || alert.message)) {
            //   this.$set(this.pageFirebaseAlert, 'alert', alert)
            //   // this.$set(this.pageFirebaseAlert, 'read', false)

            //   if (alert.allSite) { // 全站顯示
            //     this.pageFirebaseAlert.open = false
            //     if (this.pageFirebaseAlert.pages.length > 0) {
            //       this.$set(this.pageFirebaseAlert, 'pages', [])
            //     }
            //     this.checkFirebaseAlert()
            //       .then(response => {
            //         return resolve()
            //       })
            //   } else if (alert.pages) { // 各頁面顯示
            //     this.pageFirebaseAlert.open = true
            //     this.$set(this.pageFirebaseAlert, 'pages', alert.pages)
            //     this.checkFirebaseAlert(this.$nuxt.$route.name)
            //       .then(response => {
            //         return resolve()
            //       })
            //   } else {
            //     this.$alert.close()
            //     this.pageFirebaseAlert.open = false
            //     return resolve()
            //   }
            // } else if (this.$store.state.alert.open) {
            //   this.$alert.close()
            //   return resolve()
            // }
          })
        } catch (error) {
          console.log('error', error, error.response)
          return resolve()
        }
      })
    },
    async checkFirebaseAlertList (routeName) {
      const alertList = []
      for (const alert of this.pageFirebaseAlert.alertList) {
        if (alert.read) continue // 已讀過
        if (alert.allSite) { // 全站顯示
          alertList.push(alert)
        } if (alert.pages && alert.pages[routeName]) { // 各頁面顯示
          alertList.push(alert)
        }
      }
      if (alertList.length > 0) {
        this.setFirebaseAlert(alertList[0])
      } else if (this.$store.state.alert.open) {
        // this.$alert.close()
      }
    },
    async checkFirebaseAlert (routeName) {
      if (!routeName || this.pageFirebaseAlert.pages[routeName]) {
        // if (this.pageFirebaseAlert.read) return // 已讀
        await this.setFirebaseAlert()
        // this.$alert(this.pageFirebaseAlert.alert)
      } else if (this.$store.state.alert.open) {
        // this.$alert.close()
      }
    },
    async setFirebaseAlert (alert) {
      const {
        title,
        message,
        outsiteClose,
        buttons
      } = alert
      const alertButtonList = buttons
        .filter(button => button.show)
        .sort((a, b) => a.order - b.order)
        .map(button => {
          const buttonObj = {
            btnText: button.text || '確定',
            btnColor: button.color || 'primary'
          }
          buttonObj.btnClick = async (status) => {
            if (button.href) { // 按鈕連結
              if (!status) return // 點外部關閉，不做動作
              if (button.hrefTargetBlank) { // 開新頁面
                this.$set(alert, 'read', true)
                this.checkFirebaseAlertList(this.$nuxt.$route.name)
                window.open(button.href, '_blank').focus()
              } else {
                location.href = button.href
              }
            }
            if (button.clickClose || button.clickClose === undefined) {
              // this.$set(this.pageFirebaseAlert, 'read', true)
              await this.$alert.close()
              await this.$set(alert, 'read', true)
              await this.$awaitSetTimeout(100)
              this.checkFirebaseAlertList(this.$nuxt.$route.name)
            }
          }
          return buttonObj
        })
      const clickOutside = async () => {
        if (!outsiteClose) return // 點擊外部，不動作
        await this.$alert.close()
        await this.$set(alert, 'read', true)
        await this.$awaitSetTimeout(100)
        this.checkFirebaseAlertList(this.$nuxt.$route.name)
      }
      const alertSetting = {
        open: true,
        title,
        message,
        persistent: !outsiteClose,
        noBtn: alertButtonList.length === 0,
        btn: alertButtonList,
        clickOutside
      }
      this.$alert(alertSetting)
    },
    async setFirebaseAlert1 () {
      const {
        title,
        message,
        outsiteClose,
        buttons
      } = this.pageFirebaseAlert.alert
      const alertButtonList = buttons
        .filter(button => button.show)
        .sort((a, b) => a.order - b.order)
        .map(button => {
          const buttonObj = {
            btnText: button.text || '確定',
            btnColor: button.color || 'primary'
          }
          buttonObj.btnClick = (status) => {
            console.log(status)
            if (button.href) { // 按鈕連結
              if (!status) return // 點外部關閉，不做動作
              if (button.hrefTargetBlank) { // 開新頁面
                window.open(button.href, '_blank').focus()
              } else {
                location.href = button.href
              }
            }
            if (button.clickClose || button.clickClose === undefined) {
              // this.$set(this.pageFirebaseAlert, 'read', true)
              this.$alert.close()
            }
          }
          return buttonObj
        })
      const alertSetting = {
        open: true,
        title,
        message,
        persistent: !outsiteClose,
        noBtn: alertButtonList.length === 0,
        btn: alertButtonList
      }
      this.$alert(alertSetting)
    },
    async getUncheckQuotedOrder () {
      try {
        const { data } = await this.$axios.get('/api/v2/Order/Quotation/NoReply/Last')
        this.uncheckQuotedOrders = data?.Data?.LastQuotationOrder || {}
      } catch (error) {
        console.log('error', error)
      }
    },
    showQuotationTooltip () {
      this.$tooltip({
        id: 'hasUncheckQuotedOrder',
        title: '廠商報價通知',
        message: '廠商已完成報價，請點擊查看報價細節並完成付款，以避免影響您的權益',
        btnText: '前往確認報價',
        icon: 'icon-alert-circle-full',
        theme: 'warning',
        targets: '#header menu li:nth-child(6)',
        top: true,
        maxWidth: 400,
        sticky: 'reference',
        canClose: true,
        options: {
          popperOptions: {
            strategy: 'fixed'
          }
        },
        donFun: () => {
          this.$nuxt.$router.push({
            name: 'member-order-quotationInfo-idsn',
            params: { idsn: `${this.uncheckQuotedOrders.Id}` }
          })
          this.$tooltip.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  padding-top: $headerHeight;
  @media screen and (max-width: $mobileWidth) {
    padding-top: $headerHeightMobile;
  }
}
.layout-page {
  min-height: calc(100vh - #{$headerHeight} - 200px);
  min-height: calc(var(--vh, 1vh) * 100 - #{$headerHeight} - 200px);
  @media screen and (max-width: $mobileWidth) {
    min-height: calc(100vh - #{$headerHeightMobile});
    min-height: calc(var(--vh, 1vh) * 100 - #{$headerHeightMobile});
  }
}
#default-alert-dialog {
  .loading {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cookies-accept {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  color: #ffffff;
  background-color: #999999;
  transition: bottom .6s;
  z-index: 1000;
  &.show {
    bottom: 0;
  }
  .content {
    display: flex;
    padding: 10px 30px 20px;
  }
  p {
    flex: 1;
    margin-bottom: 0;
    word-break: break-word;
  }
  a {
    color: #ffffff;
  }
  .btn {
    color: #ffffff;
    flex: none;
    margin-left: 10px;
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .cookies-accept {
    .content {
      flex-direction: column;
      padding-inline: 15px;
    }
    .btn {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
</style>
