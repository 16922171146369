export default (context, inject) => {
  const setPriceFormat = (price) => price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  const awaitSetTimeout = (time) => {
    return new Promise(function (resolve) {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }
  inject('setPriceFormat', setPriceFormat)
  inject('awaitSetTimeout', awaitSetTimeout)
  context.$setPriceFormat = setPriceFormat
  context.$awaitSetTimeout = awaitSetTimeout
}
