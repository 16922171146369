<template>
  <nuxt />
</template>

<script>
export default {
  loading: false
}
</script>

<style lang="scss">
  html.print, body.print {
    min-height: 297mm;
    min-width: 210mm;
    margin: 0 !important;
    @media print {
      margin: 0 !important;
    }
  }
</style>
