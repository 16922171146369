// import axios from 'axios'

export default function ({ route, store, from }) {
  if (from && route &&
      from.name !== route.name &&
      from.name + '-id' !== route.name &&
      from.name !== route.name + '-id') {
    // router 非 replace 時，取消所有正在打的 API
    // 允許僅差「-id」的頁面，EX: 訂單列表頁、分類頁
    store.dispatch('axiosPending/clearAxiosPending')
    if (!store.state.order.status.stop) {
      store.commit('order/toggleStatus', { key: 'stop', status: true })
    }
  }
}
