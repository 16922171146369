<template>
  <v-app>
    <div class="body">
      <nuxt class="empty-page" />
    </div>
  </v-app>
</template>

<script>

export default {
  async mounted () {
    await this.$nextTick()
    await this.$awaitSetTimeout(300)
    this.$nuxt.$loading.finish()
  }
}
</script>

<style lang="scss" scoped>
</style>
